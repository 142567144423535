import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'

import { IDE_CONST, ROBOT_CONST } from './constants'
import { ACE_UTIL } from './ace-util'

export default {
  data: function () {
    return {
      isEmbed: false,
      codeEditor: null,
      fileEditor: null,
      outputEditor: null,
      editors: [],
      versionIndex: 0,
      args: null,
      stdin: null,
      isCodeExecuting: false,
      javaLibraries: [],
      outputFiles: [],
      executionTime: null,
      memory: 0,
      cpuTime: 0,
      wsNextId: 0,
      isLocalStoreEnabled: false,
      storage: null,
      files: [],
      isMultiFile: false,
      interactiveModeOption: false,
      project: null,
      autoSaveOn: false,
      ideMeta: {},
      shareNotFound: false,
      isCSharp: false,
      pymChild: null,
      showOutput: false,
      javaLibs: [],
      csharpLibs: [],
      showUpload: false,
      hideExecute: false,
      uploadInProgress: false,
      currentUpload: null,
      uploadMessage: null,
      inputFiles: [],
      maxUploads: 10,
      maxFileSize: 5000000,
      showFilesMenu: false,
      fullScreen: false,
      isMultiFileLang: false,
      isDBInitiated: false,
      tables: ""
    }
  },
  methods: {
    initEditors (count) {
      if (count > 10) {
        return
      }

      if (!window.ace) {
        this.$_.delay(this.initEditors, 500, count + 1)
        return
      }

      this.initCodeEditor()

      if (this.outputEditor) {
        this.outputEditor.getSession().setValue('')
        this.executionTime = null
      } else {
        this.initOutputEditor()
      }
    },
    initCodeEditor () {
      this.codeEditor = ACE_UTIL.initEditor(IDE_CONST.CODE_EDITOR, this.aceLanguageCode)
      this.codeEditor.renderer.setShowGutter(true)
      this.codeEditor.getSession().on('change', () => { ACE_UTIL.heightChangeFunction(IDE_CONST.CODE_EDITOR) })

      ACE_UTIL.heightChangeFunction(IDE_CONST.CODE_EDITOR, this.codeEditor)

      document.addEventListener('keyup', (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
          this.execute()
        }
      }, false)
    },
    initOutputEditor () {
      this.outputEditor = ACE_UTIL.initEditor(IDE_CONST.OUTPUT_EDITOR)
      this.outputEditor.getSession().on('change', () => { ACE_UTIL.heightChangeFunction(IDE_CONST.OUTPUT_EDITOR) })
      this.outputEditor.renderer.setPadding(20)
      this.outputEditor.renderer.setScrollMargin(20, 20)
      this.outputEditor.setDisplayIndentGuides(false)

      ACE_UTIL.heightChangeFunction(IDE_CONST.OUTPUT_EDITOR, this.outputEditor)

      $(window.ace.edit(IDE_CONST.OUTPUT_EDITOR).textInput.getElement()).keypress((event) => {
        if (this.interactiveMode && this.isCodeExecuting) {
          let key = event.key
          if (event.key === 'Enter') {
            key = '\n'
          }
          this.socketClient.send('/app/execute-i', key, { message_type: 'input' })
        }
      })
    },
    tryExecute () {
      if (this.isCodeExecuting) {
        return
      }
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('')
      this.isCodeExecuting = true
      this.executionTime = null
      this.outputFiles = []
      this.showOutput = true
      this.callViaCaptcha(this.ideExecute, this.captchaFailCallback)
    },
    captchaFailCallback () {
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue(ROBOT_CONST.ROBOT_IDE_ERROR)
      this.isCodeExecuting = false
    },
    execute () {
      if (!this.isMultiFile) {
        this.executeInteractive()
      } else {
        this.uploadFile()
      }
    },
    uploadFile () {
      let prj = window._.cloneDeep(this.project)
      if (this.project.libs && this.project.libs.length > 0 && (this.language === 'java' || this.language === 'javadblink')) {
        prj.treeData.children.push({
          name: 'maven-lib',
          editMode: true,
          parent: '/',
          markedForDeletion: false,
          children: []
        })
      }

      this.executeAPIWitoutValiation({
        url: '/api/projectSync/updateMultiFile',
        data: {
          projectKey: 1001,
          content: JSON.stringify(prj)
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: 'link',
        successAction: (data) => {
          this.showOutput = true
          if (this.language === 'html') {
            this.isCodeExecuting = false
            $("iframe[name='output']").attr('src', '')
            $("iframe[name='output']").attr('src', '/api/doodle/html-multi/index.html')
          } if (this.language === 'htmlphp') {
            this.initAdvEngine()
          } else {
            this.executeInteractive()
          }
        },
        failureAction: (status) => {
          console.log(status)
          if (status === 403) {
            this.clearRobotCheckStatus()
            window.ace.edit(IDE_CONST.OUTPUT_EDITOR).setValue('' + ROBOT_CONST.ROBOT_IDE_ERROR)
          }
          this.isCodeExecuting = false
        },
        jdaEvent: 'upload-html-multi',
        jdaLabel: ''
      })
    },
    initAdvEngine () {
      this.executeAPIWitoutValiation({
        url: '/adv-engine/init-box',
        method: 'post',
        data: {
          language: this.language,
          versionIndex: this.versionIndex,
          customer: 'link'
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: 'link',
        successAction: (data) => {
          this.isCodeExecuting = false
          $("iframe[name='output']").attr('src', '')
          if (process.env.NODE_ENV === 'development') {
            $("iframe[name='output']").attr('src', 'http://' + data.subdomain + '.k8slocalmaster.com')
          } else {
            $("iframe[name='output']").attr('src', 'https://' + data.subdomain + '.link.jdoodle.com')
          }
        },
        failureAction: (status) => {
          if (status === 403) {
            this.clearRobotCheckStatus()
            window.ace.edit('output').setValue('' + ROBOT_CONST.ROBOT_IDE_ERROR)
          }
          this.isCodeExecuting = false
        },
        jdaEvent: 'init-adv-engine',
        jdaLabel: ''
      })
    },
    initDb () {
      if (this.isDBInitiated) {
        return
      }

      this.isCodeExecuting = true

      this.executeAPIWitoutValiation({
        url: '/adv-engine/init-db',
        method: 'post',
        data: {
          language: this.language,
          versionIndex: this.versionIndex,
          initScript: this.project.dbScript,
          resetDb: this.project.resetDb,
          customer: 'craft',
          database: 'craftknowledge'
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: 'craft',
        successAction: (data) => {
          this.isDBInitiated = true
          this.showOutput = true
          this.tables = data.tables
          this.executeDbQuery()
        },
        failureAction: (status) => {
          this.showOutput = true
          this.isCodeExecuting = false
          if (status === 403) {
            this.clearRobotCheckStatus()
            window.ace.edit(IDE_CONST.OUTPUT_EDITOR).setValue('' + ROBOT_CONST.ROBOT_IDE_ERROR)
          } else {
            window.ace.edit(IDE_CONST.OUTPUT_EDITOR).setValue('Unable to initiate database')
          }
        },
        jdaEvent: 'init-db-engine',
        jdaLabel: ''
      })
    },
    executeDbQuery () {
      if (!this.isDBInitiated) {
        this.initDb()
        return
      }

      let query = window.ace.edit(IDE_CONST.CODE_EDITOR).getSelectedText();

      if (query.length < 1) {
        query = window.ace.edit(IDE_CONST.CODE_EDITOR).getValue()
      }
      this.isCodeExecuting = true
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).setValue('')
      this.executeAPIWitoutValiation({
        url: '/adv-engine/execute-db-query',
        method: 'post',
        data: {
          language: this.language,
          versionIndex: this.versionIndex,
          script: query,
          customer: 'craft',
          database: 'craftknowledge'
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: 'craft',
        successAction: (data) => {
          this.isDBInitiated = true
          window.ace.edit(IDE_CONST.OUTPUT_EDITOR).setValue(data.output)
          this.tables = data.tables
          this.isCodeExecuting = false
        },
        failureAction: (status) => {
          if (status === 403) {
            this.clearRobotCheckStatus()
            window.ace.edit(IDE_CONST.OUTPUT_EDITOR).setValue('' + ROBOT_CONST.ROBOT_IDE_ERROR)
          }
          this.isCodeExecuting = false
        },
        jdaEvent: 'execute-db-query',
        jdaLabel: ''
      })
    },
    postExecuteSuccessHandling () {

    },
    executeInteractive () {
      this.socketClient = Stomp.over(new SockJS('/engine/stomp'), { heartbeat: false, debug: false })

      this.wsNextId = 0

      let startTime = 0
      try {
        if (window.performance) {
          startTime = performance.now()
        }
      } catch (e) {
        startTime = $.now()
      }

      let libraries = window._.clone(this.project.libs)
      let data = {}

      if (this.isMultiFile) {
        data = {
          language: this.language,
          versionIndex: this.versionIndex,
          projectKey: 1001,
          multiFile: true,
          libs: libraries,
          dbScript: this.project.dbScript,
          mainFile: this.project.home.substring(1),
          hasInputFiles: (this.inputFiles.length > 0)
        }
      } else {
        data = {
          script: window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue(),
          dbScript: this.project.dbScript,
          args: this.args,
          language: this.language,
          libs: libraries,
          versionIndex: this.versionIndex,
          projectKey: 1001,
          hasInputFiles: (this.inputFiles.length > 0)
        }
      }

      // TODO : pageView for interactive call
      this.socketClient.connect({}, () => {
        this.onWsConnection(startTime, data)
      }, this.onWsConnectionFailed)
    },
    onWsConnectionFailed (e) {
      this.isCodeExecuting = false
      window.ace.edit('output').insert('Connection to server lost.\nIt is possible your browser or internet connection may not support the ' +
        'Interactive mode.\nPlease try again, or try Non-Interactive mode. Alternatively contact JDoodle ' +
        'support at jdoodle@nutpan.com.')
    },
    postInteractiveExecute (startTime) {
      this.socketClient.disconnect()
      this.isCodeExecuting = false

      // TODO : pageView for interactive call completion

      // eslint-disable-next-line no-unused-vars
      let execTime
      try {
        if (window.performance) {
          execTime = performance.now() - startTime
        }
      } catch (e) {
        execTime = $.now() - startTime
      }

      // TODO : pageView for interactive call execute time
      this.postExecuteSuccessHandling()
    },
    onWsConnection (startTime, data) {
      this.socketClient.subscribe('/user/queue/execute-i', (message) => {
        let msgId = message.headers['message-id']
        let msgSeq = parseInt(msgId.substring(msgId.lastIndexOf('-') + 1))

        let statusCode = parseInt(message.headers.statusCode)

        if (statusCode === 201) {
          this.wsNextId = msgSeq + 1
          return
        }

        let t0
        try {
          t0 = performance.now()
          while ((performance.now() - t0) < 2500 && this.wsNextId !== msgSeq) {

          }
        } catch (e) {

        }

        if (statusCode === 204) {
          this.executionTime = message.body
          this.postInteractiveExecute(startTime)
        } else if (statusCode === 206) {
          this.outputFiles = JSON.parse(message.body)
        } else if (statusCode === 403) {
          this.clearRobotCheckStatus()
          window.ace.edit('output').insert('' + message.body)
        } else if (statusCode !== 410) {
          window.ace.edit('output').insert('' + message.body)
        }

        this.wsNextId = msgSeq + 1
      })

      this.socketClient.send('/app/execute-i', JSON.stringify(data), { message_type: 'execute' })
      window.ace.edit('output').focus()
    },
    reset () {
      window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(this.project.script)
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('')
      this.args = null
      this.stdin = null
      this.javaLibraries = []
      this.executionTime = null
      this.memory = 0
      this.cpuTime = 0
    },
    addToRecent: function (lastExecution) {
      if (this.isEmbed) {
        return
      }
      var recent = JSON.parse(this.storage.getItem('JDoodleRecent'))
      if (!recent) {
        recent = {}
      }

      if (!recent[this.language]) {
        recent[this.language] = []
      }

      recent[this.language].unshift(lastExecution)
      if (recent[this.language].length > 50) {
        recent[this.language] = window._.slice(recent[this.language], 0, 50)
      }

      this.storage.setItem('JDoodleRecent', JSON.stringify(recent))
    },
    stopExecution () {
      if (!this.isCodeExecuting) {
        return
      }

      this.socketClient.disconnect(() => {
        this.isCodeExecuting = false
        this.outputMessage = 'Execution suspended'
      })
    },
    expand () {
      this.pymChild.sendMessage(this.fullScreen ? 'compress' : 'expand', JSON.stringify({ id: this.$route.query.id }))
      this.fullScreen = !this.fullScreen
    },
    copyToClipboard () {
      window.navigator.clipboard.writeText(window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue())
    }
  }
}
