import { SERVER_ERROR } from './constants'

export default {
  data: function () {
    return {
      doodleForm: {
        inProgress: false,
        completed: false,
        errorMessage: null
      }
    }
  },
  computed: {
    showMainSection () {
      return !this.doodleForm.inProgress && !this.doodleForm.completed
    },
    showInProgressSection () {
      return this.doodleForm.inProgress
    }
  },
  methods: {
    executeAPIWitoutValiation: function (apiRequest) {
      apiRequest.form.completed = false
      apiRequest.form.errorMessage = null
      apiRequest.form.inProgress = true
      $.ajax({
        url: apiRequest.url,
        method: apiRequest.method,
        data: apiRequest.data
      }).fail((jqXHR, textStatus, errorThrown) => {
        if (jqXHR.status >= 500) {
          apiRequest.form.errorMessage = SERVER_ERROR
        } else if (jqXHR.status === 401) {
          apiRequest.form.errorMessage = 'Your session expired, please login and try again'
          this.$_.delay(() => {
            this.$router.push('/')
          }, 5000, '')
        } else if (jqXHR.responseJSON) {
          apiRequest.form.errorMessage = jqXHR.responseJSON.message
        }
        console.log(jqXHR)
        if (apiRequest.failureAction) {
          apiRequest.failureAction(jqXHR.status)
        }
      }).done((data) => {
        if (apiRequest.markCompleted) {
          apiRequest.form.completed = true
        }
        if (apiRequest.successAction) {
          apiRequest.successAction(data)
        }
      }).always(() => {
        apiRequest.form.inProgress = false
      })
    },
    executeAPI (apiRequest) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.executeAPIWitoutValiation(apiRequest)
        }
      })
    }
  }
}
